/**
 * 初始化企业注册基本信息
 *
 * @export
 * @return {*}  {CompanyBasicInfo}
 */
export const createCompanyBasicInfo = (): CompanyBasicInfo => ({
  //   id: number,
  // 头像
  avatar: '',
  // 简称
  alias: '',
  // 企业名称
  registrationName: '',
  // 统一社会信用代码
  registrationId: '',
  // 企业所属行业
  companyIndustry: '',
  // 企业规模
  enterpriseSize: '',
  // 企业法人
  enterpriseLegalPerson: '',
  // 注册地址
  registrationAddress: '',
  // 联系地址
  contactAddress: '',
  // 联系电话
  contactPhone: '',
  // 邮箱
  contactEmail: '',
});

/**
 * 初始化教育经历
 *
 * @export
 * @return {*}  {CompanySupplementInfo}
 */
export const createCompanySupplementInfo = (): CompanySupplementInfo => ({
  // id?: number,
  // 企业业务
  businessDescription: '',
  // 网址
  enterpriseWebsite: '',
  // 证件
});
